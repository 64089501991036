import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { createMarkUp } from '../helpers';
import { ComponentStatisticsBlock, ItemContainer, Item, Stat } from './styles.jsx';

interface Props {
  Title?: string;
  Items?: object;
  AdditionalClass?: string;
  Theme?: 'light' | 'dark';
}

export const StatisticsBlock = ({ Title = '', AdditionalClass = '', Items = [], Theme = 'light', ...props }: Props) => {
  const stripTagsAndNewlines = inputString => {
    // Remove <p> tags
    let strippedString = inputString.replace(/<\/?p>/gi, '');
    // Remove newline characters
    strippedString = strippedString.replace(/\n/g, '');
    return strippedString;
  };

  return (
    <ComponentStatisticsBlock className={`Component-StatisticsBlock theme-${Theme} ${AdditionalClass}`} {...props}>
      <GlobalStyle />
      <div className={'container'}>
        {Title && <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>}

        <ItemContainer>
          {Items.map((item, index) => (
            <Item key={index}>
              <Stat className={'alignment-' + item.order}>
                <div className={'line-wrap'}>
                  <div className={'inner'}>
                    {item.lines.map((line, key) => {
                      return (
                        <span
                          key={key}
                          className={'type-' + line.type}
                          dangerouslySetInnerHTML={createMarkUp(stripTagsAndNewlines(line.copy))}
                        ></span>
                      );
                    })}
                  </div>
                </div>
                <p dangerouslySetInnerHTML={createMarkUp(item.description)}></p>
              </Stat>
            </Item>
          ))}
        </ItemContainer>
      </div>
    </ComponentStatisticsBlock>
  );
};
