import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients} from "../../../GlobalStyles";

export const FooterButtonOverlayStyles = styled.div`
  margin-bottom: -63px;
  position: relative;
  z-index: 15;
  
  .wrapper {
    padding: 24px;
    background: ${bgGradients.light};
    border-radius: 4px;
    text-align: center;
    h4 {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: 0em;
      color: white;
      margin-bottom: 16px;
      margin-right: 16px;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      padding: 48px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      h4{
        font-size: 32px;
        margin-bottom: 0;
      }
    }
  }
    
    &.Theme-primary{
        margin-bottom: 0px;
        .wrapper{
            background: ${colors.primary};
        }
    }


`;
