import styled from 'styled-components';

import { colors, mediaQueriesSizes } from '../../../GlobalStyles';

export const ComponentAccordion = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  h2 {
    margin-bottom: 24px;
    margin-top: 0;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      margin-bottom: 32px;
    }
  }

  &.theme-dark {
    background: ${colors.primary};

    h2 {
      color: ${colors.white};
    }

    ul {
      li {
        border-color: rgba(255, 255, 255, 0.7);

        &:before,
        &:after {
          background: rgba(255, 255, 255, 0.7);
        }

        h4 {
          color: ${colors.white};
        }

        p,
        a {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
`;

export const ListWrapper = styled.ul`
  padding: 0;
  margin: 24px 0 0;

  & > li {
    list-style: none;
    cursor: pointer;
    padding: 24px 0;
    border-bottom: solid 1px ${colors.border};
    transition: 0.3s;
    position: relative;

    &:first-of-type {
      padding-top: 0;
      &:before,
      &:after {
        top: 16px;
      }
    }

    &:before,
    &:after {
      pointer-events: none;
      position: absolute;
      top: 37px;
      right: 0;
      width: 18px;
      height: 3px;
      content: '';
      display: block;
      transition: 0.3s;
      background: ${colors.primary};
    }

    &:after {
      transform: rotate(90deg);
    }

    div.copy {
      display: none;
      p,
      span {
        font-weight: 300;
        display: inline;
      }

      span.clickable {
        color: ${colors.primary};
        text-decoration: underline;
      }

      a {
        color: ${colors.primary};
      }

      ul {
        margin: 32px 0;
        padding-left: 16px;
        display: block !important;
        li {
          list-style: initial;
          margin-bottom: 16px;
        }
      }

      hr {
        margin: 24px 0;
      }
      
      div.data-type-wp{
        p{
          display: block;
          margin: 24px 0 0;
        }
        ul{
            li{
                font-size: 18px;
                margin-bottom: 0;
                color: ${colors.light};
                font-family: poppins;
            }
        }
      }
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.2;
      color: ${colors.primary};
      padding-right: 24px;
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        font-size: 24px;
      }
    }

    &:hover {
      transform: scale(1.01);
    }

    &.active {
      div.copy {
        margin-top: 24px;
        display: block;
          
          
          
      }

      &:after {
        transform: rotate(0deg);
      }
    }
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    margin-top: 75px;
  }
`;
