import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import GlobalStyle, { BackgroundGrey, BackgroundHalfGreyReverse, BackgroundBlue } from '../GlobalStyles';

import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { Accordion } from '../components/V2/Accordion/Accordion';
import { SplitColumn } from '../components/V2/SplitColumn/SplitColumn';
import { SimpleTitle } from '../components/V2/SimpleTitle/SimpleTitle';
import { HighlightBlockNoImage } from '../components/V2/HighlightBlocks/HighlightBlockNoImage/HighlightBlockNoImage';
import { StatisticsBlock } from '../components/V2/StatisticsBlock/StatisticsBlock';
import { LogoGrid } from '../components/V2/LogoGrid/LogoGrid';
import { FooterButtonOverlay } from '../components/V2/FooterButtonOverlay/FooterButtonOverlay';

interface Props {
  Lang?: string;
  Data: object;
}

const CNCTemplate = ({ Lang = 'en', Data, ...props }: Props) => {
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);

  return (
    <>
      <PageContainer>
        <BackgroundGrey>
          <HelmetContainer currentPage={'dental-cnc'} lang={Lang} title={Data.fields.page_meta.seo.page_title} />
          <Header lang={Lang} />

          <LargeHero
            AdditionalClasses={'CNC-Homepage'}
            Country={Lang}
            BackgroundStyle={'image'}
            Logo={'manufacturing-os'}
            Copy={`<h1><b>${Data.fields.hero.title}</b></h1><p>${Data.fields.hero.copy}</p>`}
            Background={Data.fields.hero.image}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              SourceID: 'request-demo',
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true,
              theme: 'primary'
            }}
          />
        </BackgroundGrey>

        <StatisticsBlock
          AdditionalClass={'cnc-dental-page'}
          Items={[
            {
              order: 'rows',
              description: Data.fields.statistics.statistics[0].description,
              lines: [
                {
                  copy: 'At least',
                  type: Data.fields.statistics.statistics[0].lines[0].type
                },
                Data.fields.statistics.statistics[0].lines[1]
              ]
            },
            {
              order: 'rows',
              description: Data.fields.statistics.statistics[1].description,
              lines: [
                {
                  copy: '<p>100%</p>',
                  type: 'bold'
                },
                {
                  copy: 'ready',
                  type: 'default'
                }
              ]
            }
          ]}
        />

        <SplitColumn
          Copy={`<h2>${Data.fields.video_block.title}</h2>`}
          MediaType={'Video'}
          Video={Data.fields.video_block.video_url}
          Background={'Primary'}
        />

        <BackgroundGrey>
          <SimpleTitle AddHR={false} Title={Data.fields.workflow.title} />

          {Data.fields.workflow.blocks.map((block, index) => {
            let orientation = 'Left';
            if (index % 2) {
              orientation = 'Right';
            }

            return (
              <SplitColumn
                key={index}
                ImageBackground={false}
                ImageOverlap={false}
                ImagePosition={orientation}
                Copy={`<h5>${block.title}</h5>${block.copy}`}
                Image={{
                  src: block.image,
                  alt: ''
                }}
              />
            );
          })}
        </BackgroundGrey>
        <SplitColumn
          Background={'White'}
          ImageBackground={false}
          ImageOverlap={false}
          ImagePosition={'Left'}
          Copy={`<h6>${Data.fields.split_block.subtitle}</h6><h3>${Data.fields.split_block.title}</h3>${Data.fields.split_block.copy}`}
          Image={{
            src: Data.fields.split_block.image,
            alt: ''
          }}
        />

        <BackgroundGrey>
          <Accordion DataType={'wp'} Title={Data.fields.faqs.faqs_title} Items={Data.fields.faqs.questions} />

          <HighlightBlockNoImage
            Theme={'Primary'}
            Title={`${Data.fields.highligh_block.title}`}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              SourceID: 'request-demo',
              OpenDemoModel: true,
              copy: globalCopy.Buttons.RequestDemo
            }}
          />

          <ArticleBlock
            Theme={'basic'}
            Title={Data.fields.article_block.title}
            Layout={'layout-4'}
            MostRecentBlogs={true}
            Topic={'dental'}
            Link={{
              copy: 'All Insights',
              link: '/news/'
            }}
          />
        </BackgroundGrey>

        <LogoGrid
          Title={Data.fields.partners_block.title}
          Logos={[
            {
              src: '/assets/images/industry-partners/EOS.png',
              alt: 'EOS'
            },
            {
              src: '/assets/images/industry-partners/Renishaw.png',
              alt: 'Renishaw'
            },
            {
              src: '/assets/images/industry-partners/HP.png',
              alt: 'HP'
            },
            {
              src: '/assets/images/industry-partners/E-Plus-3D.png',
              alt: 'E-Plus-3D'
            },
            {
              src: '/assets/images/industry-partners/Labtrac.png',
              alt: 'Labtrac'
            },
            {
              src: '/assets/images/industry-partners/Trumpf.png',
              alt: 'Trumpf'
            },
            {
              src: '/assets/images/industry-partners/3DSystems.png',
              alt: '3DSystems'
            },
            {
              src: '/assets/images/industry-partners/dgshape.png',
              alt: 'DGShape'
            },
            {
              src: '/assets/images/industry-partners/Sisma.png',
              alt: 'Sisma'
            },
            {
              src: '/assets/images/industry-partners/DMGMori.png',
              alt: 'DMGMori'
            },
            {
              src: '/assets/images/industry-partners/SLMSolutions.png',
              alt: 'SLMSolutions'
            },
            {
              src: '/assets/images/industry-partners/nexa3D.png',
              alt: 'nexa3D'
            },
            {
              src: '/assets/images/industry-partners/imes-icore.png',
              alt: 'imes-icore'
            },
            {
              src: '/assets/images/industry-partners/PRODWAYS.png',
              alt: 'PRODWAYS'
            },
            {
              src: '/assets/images/industry-partners/Stratasys.png',
              alt: 'Stratasys'
            },
            {
              src: '/assets/images/industry-partners/2onelab.png',
              alt: '2onelab'
            },
            {
              src: '/assets/images/industry-partners/xact-metal-logo.png',
              alt: 'Xact Metal'
            }
          ]}
        />

        <FooterButtonOverlay
          Theme={'primary'}
          Title={'Do you also have a 3D printer?  No problem. We have this covered for you.'}
          Button={{
            Link: '/',
            Label: 'Learn More'
          }}
        />

        <SplitColumn
          Background={'White'}
          ImageBackground={false}
          ImageOverlap={false}
          ImagePosition={'Left'}
          Copy={`<h6>${Data.fields.split_block_2.subtitle}</h6><h3>${Data.fields.split_block_2.title}</h3>${Data.fields.split_block_2.copy}`}
          Image={{
            src: Data.fields.split_block_2.image,
            alt: ''
          }}
          Button={{
            copy: globalCopy.Buttons.Learn,
            theme: 'accent-teal',
            link: '/'
          }}
        />

        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default CNCTemplate;
