import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const ComponentStatisticsBlock = styled.div`
    padding: 32px 0;
    margin-top: 32px;

    h2 {
        font-size: 48px;
        font-weight: 400;
        line-height: 56px;
        letter-spacing: 0.02em;
        text-align: center;
        color: ${colors.primary};

        b {
            font-weight: 700;
        }
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 64px 0;
        margin-top: 64px;
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        padding: 128px 0;
    }

    &.cnc-dental-page {
        margin-top: 0;
        padding-top: 0;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            padding-top: 24px;
        }

        @media (min-width: ${mediaQueriesSizes.xl}px) {
            padding-bottom: 54px;

        }
    }

`;

export const ItemContainer = styled.div`

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: flex;
    max-width: 950px;
    margin: 0 auto;
    justify-content: space-evenly;
  }

`;


export const Item = styled.div`
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: flex;
  }
`;

export const Stat = styled.div`
  flex: 1 0 0%;

  .line-wrap {
    height: 150px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  span {
    display: block;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.01em;
    text-align: center;
    color: ${colors.light};
    line-height: 1;

    &.type-bold {
      font-size: 56px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.01em;
      text-align: center;
      color: ${colors.primary};
    }
  }

  &.alignment-cols {
    .inner {
      display: flex;

      span {
        text-align: left;
        line-height: 0.85;
        &.type-bold {
          line-height: 0.8;  
        }
      }
    }
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: center;
    max-width: 250px;
    padding: 0 16px;
    margin: 0 auto;
  }


`;