import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { ButtonComponent } from '../Button/Button';
import { FooterButtonOverlayStyles } from './styles.jsx';

interface Props {
  Title?: string;
  Button?: object;
  Theme?: string;
}

export const FooterButtonOverlay = ({ Theme = '', Title = '', Button, ...props }: Props) => {
  return (
    <FooterButtonOverlayStyles className={`Component-FooterButtonOverlay Theme-${Theme}`} {...props}>
      <GlobalStyle />
      <div className={'container'}>
        <div className={'wrapper'}>
          <h4>{Title}</h4>
          {Button && (
            <ButtonComponent
              SourceID={Button.SourceID}
              Theme={'white-and-primary'}
              Link={Button.Link}
              Label={Button.Label}
              OpenDemoModel={Button.OpenDemoModel}
            />
          )}
        </div>
      </div>
    </FooterButtonOverlayStyles>
  );
};
