import React from 'react';

import CNCTemplate from './../templates/CNCTemplate';
const CNCDental = () => {
  const Lang = 'en';
  const wordpressData = require(`../../data/wordpress-data/4141.json`);

  return <CNCTemplate Lang={Lang} Data={wordpressData} />;
};

export default CNCDental;
